import React, { useEffect, useState } from 'react';
import { any } from 'ramda';
import ReactEcharts from 'echarts-for-react';
import ReactToolTip from '@/src/components/Base/reactToolTip';
import { getAvgArrivalTime, getHistoryPunctualityPromise, getOption } from './utils';
import type { HistoryPunctualityType } from './types';

type Props = {
	flightNo: string;
	parentId;
	arrivalPunctuality;
	arrivalDateTime;
};

/**
 * 历史到达准点率
 */
const HistoryPunctualityPopup = ({ flightNo, parentId, arrivalPunctuality, arrivalDateTime }: Props) => {
	const [historyPunctualityData, setHistoryPunctualityData] = useState<HistoryPunctualityType[]>([]);
	const [avgArrivalDateTime, setAvgArrivalDateTime] = useState('--');

	useEffect(() => {
		getHistoryPunctualityPromise(flightNo).then((json) => {
			if (json && json.data && json.data.length) {
				const data = json.data;
				const avgArrivalTime = getAvgArrivalTime({ arrivalDateTime, delays: data });
				setHistoryPunctualityData(data);
				setAvgArrivalDateTime(avgArrivalTime);
			}
		});
	}, [flightNo]);

	const isExistData = any(
		(historyPunctualityItem) => !!historyPunctualityItem.arrivalDelayMinutes,
		historyPunctualityData
	);

	return historyPunctualityData.length ? (
		<ReactToolTip
			group="flightPunctualityPopup"
			key={parentId}
			parentId={parentId}
			toolTipContent={
				<div className="popups popup-punctuality">
					<div className="title">到达准点率</div>
					<div className="body">
						<div className="sum">
							<div className="left">
								历史到达准点率 <span className="value">{arrivalPunctuality}</span>
							</div>
							<div className="right">
								平均到达时间 <span className="value">{avgArrivalDateTime}</span>
							</div>
						</div>
						{isExistData && (
							<ReactEcharts
								option={getOption(historyPunctualityData)}
								style={{ height: '325px', width: '384px' }}
							/>
						)}
					</div>
				</div>
			}
		/>
	) : null;
};

export default HistoryPunctualityPopup;
