import moment from 'moment';
import CONFIG_URL from '../../../../constants/list/api';
import { fetchGet } from '../../../../actions/common';

const HistoryPunctualityPromise = {};

export const getHistoryPunctualityPromise = (flightNo) => {
	return (
		HistoryPunctualityPromise[flightNo] ||
		fetchGet(CONFIG_URL.getHistoryPunctuality, { flightNo }).then((res) => {
			if (res && res.data) {
				HistoryPunctualityPromise[flightNo] = new Promise((resolve) => {
					resolve(res.data);
				});
				return res.data;
			}
		})
	);
};

export const transferMinuteToHour = (minutes) => minutes / 60;

export const getOption = (data: any[]) => {
	const xDataList = data.map((item) => moment(item.date).format('MM/DD'));

	const minArrDelayInHour = +Math.min(...data.map((item) => transferMinuteToHour(item.arrivalDelayMinutes))).toFixed(
		1
	);
	const maxArrDelayInHour = +Math.max(...data.map((item) => transferMinuteToHour(item.arrivalDelayMinutes))).toFixed(
		1
	);

	return {
		backgroundColor: '#fff',
		textStyle: {
			color: '#999',
		},
		grid: {
			right: '18',
			left: '45',
			top: '30',
			bottom: '35',
		},
		tooltip: {
			show: false,
		},
		xAxis: [
			{
				data: xDataList,
				splitLine: { show: false },
				splitArea: { show: false },
				axisLine: {
					show: false,
					lineStyle: {
						color: '#99aeca',
					},
				},
				axisTick: {
					show: false,
				},
				nameTextStyle: {
					fontSize: '12px',
					color: '#999999',
				},
				axisLabel: {
					show: true,
					margin: 21,
				},
			},
		],
		yAxis: [
			{
				inverse: false,
				splitArea: { show: false },
				axisLine: {
					show: false,
				},
				axisTick: {
					show: false,
				},
				axisLabel: {
					color: '#999999',
					formatter: function (value) {
						if (value === 0) {
							return '{ontime|准点}';
						} else if (value < minArrDelayInHour && minArrDelayInHour < 0) {
							return '{delay|提前}';
						} else if (value > maxArrDelayInHour && maxArrDelayInHour > 0) {
							return '{delay|延误}';
						} else {
							return value + 'h';
						}
					},
					rich: {
						ontime: {
							fontSize: 14,
							color: '#5678A8',
						},
						delay: {
							fontSize: 14,
						},
					},
				},
			},
		],
		series: [
			{
				name: '到达准点率',
				data: data.map((item) => {
					const value = item.arrivalDelayMinutes;
					// 正负数显示的柱状图颜色不同
					const color = value > 0 ? '#FF7528' : '#0086F6',
						labelPosition = value > 0 ? 'top' : 'bottom';

					return {
						value: transferMinuteToHour(value),
						label: {
							position: labelPosition,
							color: '#5678A8',
							show: true,
							formatter: ({ value }) => {
								value = +value;

								if (value !== 0) {
									value = (60 * value).toFixed(0);

									const positive = value > 0;
									const absVals = Math.abs(value);
									const hours = Math.floor(absVals / 60);
									const minutes = absVals - hours * 60;

									return `${positive ? '' : '-'}${hours ? `${hours}h` : ''}${
										minutes ? `${minutes}m` : ''
									}`;
								} else {
									return '';
								}
							},
						},
						itemStyle: {
							color,
							opacity: 0.5,
						},
					};
				}),
				type: 'bar',
				stack: 'one',
				barWidth: 22,
				markPoint: {
					data: data.map((item, index) => {
						const value = item.arrivalDelayMinutes;
						// 正负数显示的柱状图端部颜色不同
						const color = value > 0 ? '#ff7528' : '#0086f6';

						return {
							coord: [index, transferMinuteToHour(value)],
							itemStyle: { color },
						};
					}),
					symbol: 'rect',
					symbolSize: [22, 2],
					label: {
						show: false,
					},
				},
			},
		],
	};
};

export const getAvgArrivalTime = ({ arrivalDateTime, delays }) => {
	const totalArrivalDelay = delays.reduce((total, item) => {
		return total + item.arrivalDelayMinutes;
	}, 0);
	const avgDelay = parseInt(totalArrivalDelay / delays.length);
	const avgArrivalTime = moment(arrivalDateTime).add(avgDelay, 'm');
	return avgArrivalTime.format('HH:mm');
};
